@import 'styles/colors/var.css';

.message_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 24px;
  padding: 32px 32px 32px 32px;
}

.message_container_info {
  background-color: var(--color-neutral-100);
  color: var(--color-neutral-600);
}

.message_container_error {
  background-color: var(--color-error-100);
  color: var(--color-error-500);
}

.message_container_warning {
  background-color: var(--color-warning-100);
  color: var(--color-warning-500);
}

.message_container_success {
  background-color: var(--color-success-100);
  color: var(--color-success-500);
}

.message_text {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
}

.message_icon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  padding-right: 8px;
}
