@import 'styles/colors/var.css';

.info_title {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
}

.info_text {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  white-space: pre-line;
}

.transfer_text {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 600;
}

.phonebook_option {
  padding-top: 10px;
}

.phone_book_selection {
  padding: 0 !important;
  margin-bottom: 32px;
}

.transfer_numbers_container {
  flex-grow: 1;
}

@media (max-width: 576px) {
  .phone_book_selection {
    margin-bottom: 0;
  }
}
