@import './colors/var.css';

p,
span,
h2,
div {
  font-family: var(--font-family);
}

.category_title {
  font-family: var(--font-family);
  font-size: 32px;
  font-weight: 700;
  color: var(--color-neutral-600);
}

.package_card_wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-neutral-100);
  padding: 48px 48px 32px 48px;
  margin-bottom: 24px;
}

.package_card_wrapper_selected {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-neutral-100);
  padding: 48px 48px 32px 48px;
  margin-bottom: 24px;
  border: 1px solid var(--color-neutral-600);
}

.yellow-highlight {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-neutral-100);
  padding: 48px 48px 32px 48px;
  margin-bottom: 24px;
  border: 2px solid #ffff00;
}

.package_additional_options_wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--color-neutral-100);
  padding: 32px;
  margin-bottom: 24px;
}

.package_name {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 700;
  color: var(--color-neutral-600);
}

.package_info_wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
}

.package_info_text {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
}

.package_speed {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  color: var(--color-neutral-600);
}

.error_text_style {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  color: red;
}

.price_wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.package_price {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  text-align: right;
  font-size: 20px;
  font-weight: 700;
  white-space: nowrap;
}

.show_more {
  color: var(--color-neutral-500);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
}

.show_more:hover {
  color: var(--color-neutral-500);
  text-decoration-line: underline;
}

.shipping_price_label {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  text-align: right;
  font-size: 14px;
  font-weight: 400;
}

.shipping_price {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 14px;
}

.input_component {
  border-radius: 0px;
  height: 48px;
}

.margin_left_sm {
  margin-left: 12px;
}

.margin_bottom_xs {
  margin-bottom: 6px;
}

.margin_bottom_sm {
  margin-bottom: 12px;
}

.margin_bottom_m {
  margin-bottom: 24px;
}

.margin_bottom_l {
  margin-bottom: 32px;
}

.margin_top_sm {
  margin-top: 12px;
}

.margin_top_md {
  margin-top: 24px;
}

.collapse_header_wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.collapse_header_wrapper svg {
  width: 32px;
  height: 32px;
}

.collapse_header_wrapper svg path {
  fill: #000;
}

.product_image {
  width: 100%;
  height: 74px;
}

.product_image_iptv {
  width: 100%;
  height: 80px;
}

.checked_icon {
  background-color: var(--color-neutral-600);
  border-radius: 50%;
  padding: 4px;
}

@media only screen and (max-width: 768px) {
  .package_card_wrapper,
  .package_card_wrapper_selected,
  .yellow-highlight {
    padding: 48px 32px 32px 32px;
  }
}
