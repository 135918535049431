@import 'styles/colors/var.css';

.App {
  display: flex;
  flex: 1;
  width: 100%;
  font-family: var(--font-family);
}

.offer_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 12px;
}

.ant-col,
.ant-row,
.ant-btn,
.ant-select,
.ant-picker,
.ant-checkbox,
.ant-radio,
.ant-input,
.ant-radio-group {
  font-family: var(--font-family);
}

h3,
span,
div {
  font-family: var(--font-family);
}

h1,
small {
  color: #4e4e4e;
  font-weight: 600;
}

h1 {
  font-weight: 700;
}

p {
  margin: 0;
}

.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--color-neutral-600) !important;
  color: var(--color-neutral-50) !important;
  border: none !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--color-primary-300) !important;
  color: var(--color-neutral-50) !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--color-neutral-600) !important;
}

.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--color-neutral-600) !important;
}

.ant-collapse {
  width: 100%;
}

.ant-radio-wrapper .ant-radio-inner {
  width: 20px;
  height: 20px;
}

.ant-steps-item-title {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-weight: 600;
  letter-spacing: -0.64px !important;
  line-height: 22px !important;
}

.ant-collapse-item {
  border-bottom: 1px solid var(--color-neutral-200) !important;
  border-radius: 0px !important;
}

.ant-select {
  position: unset !important;
}

.ant-select-selector {
  border-radius: 0px !important;
  border-color: var(--color-neutral-300) !important;
  outline: none !important;
  box-shadow: none !important;
}

.ant-select-outlined.ant-select-status-error:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #ff4d4f !important;
}

.ant-select .ant-select-selection-item {
  color: var(--color-neutral-600);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
}

.ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 0;
  margin-right: 4px;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: var(--color-neutral-50);
}

.custom_style_checkbox:hover .ant-checkbox-inner,
.custom_style_checkbox:focus .ant-checkbox-inner {
  border-color: var(--color-neutral-600) !important;
}

.custom_style_checkbox_error .ant-checkbox-inner {
  border-color: red !important;
}

.custom_style_checkbox input:checked + .ant-checkbox-inner {
  background-color: var(--color-neutral-600) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: var(--color-neutral-600) !important;
  background-color: var(--color-neutral-600) !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex;
  align-items: center;
  flex: 1;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--color-neutral-600);
  border-color: var(--color-neutral-600);
}

.ant-radio:hover .ant-radio-inner {
  border-color: var(--color-neutral-600);
  outline: none;
  box-shadow: none;
}

.custom_style_checkbox .ant-checkbox,
.custom_style_radio .ant-radio {
  align-self: flex-start;
  margin-top: 4px;
}

.ant-picker {
  width: 100% !important;
  height: 55px !important;
  border-radius: 0 !important;
  border-color: var(--color-neutral-300);
  color: var(--color-neutral-600);
}

.ant-picker-outlined:hover,
.ant-picker-outlined:focus-within {
  border-color: var(--color-neutral-300) !important;
  color: var(--color-neutral-600);
  box-shadow: none;
}

.charity_select_dropdown {
  width: 400px !important;
}

input[type='number']::-webkit-inner-spin-button {
  display: none;
}

.ant-collapse.ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  padding: 0 !important;
}

.ant-modal.invalid-session .ant-modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2rem;
}

@media screen and (max-width: 980px) {
  .App {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .ant-steps.ant-steps-vertical {
    flex-direction: row;
    position: relative;
  }

  .ant-steps .ant-steps-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-steps.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: unset;
  }

  .ant-steps-item-container > .ant-steps-item-tail {
    display: none !important;
  }

  .charity_select_dropdown {
    width: 80% !important;
  }
}
